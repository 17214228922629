import * as api from "@/api/common/index.js";
import {defaultOfferWall} from '@/config/index';
export const setLocal = (name, data) => {
  let value = JSON.stringify(data)
  localStorage.setItem(name, value);
}
export const getLocal = (name, data) => {
  let value = localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : undefined
  return value;
}
export const removeLocal = (name, ) => {
  if (name) {
    localStorage.removeItem(name);
  } else {
    localStorage.clear()
  }
}
export const throttle = (func, limit)  => {
    let waiting = false;
    return (...args) => {
      if (!waiting) {
        waiting = true;
        setTimeout(() => {
            func(...args);
          waiting = false;
        }, limit);
      }
    }
}
export const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
        func(...args);
        }, delay);
    };
}
export const currencyTrf = (str) => {
  const obj = {
    "USD": "$",
    "CNY": "¥"
  }
  console.log(obj[str], 'obj[str]')
  return obj[str]
}
export const uaInfo = () => {
  return process.env.NODE_ENV == "development" ? "Mozilla/5.0 (Linux; Android 12; moto g pure Build/S3RHS32.20-42-10-14-2-3; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/112.0.5615.135 Mobile Safari/537.36" : navigator.userAgent
}

// 打点
export const dotData = ({eventName, eventInfo})=>{
  try {
    api.syncProgress({
      svName: '/hhstat',
      mdName: 'post',
      // eslint-disable-next-line no-undef
      googleId: offerWall.gi || defaultOfferWall.gi,
      eventName: eventName,  //事件名
      eventInfo: eventInfo,  //拓展json
      // eslint-disable-next-line no-undef
      productVersion: offerWall.productVersion || defaultOfferWall.productVersion
    }).then(val=>{
      console.log('打点', val);
    })
  } catch (e) {
    console.log(e, 'android no set!!!!')
  }

}