const cacheObserverObject = {}

const intersectionObserver = new IntersectionObserver((entries) => {
  // 如果 intersectionRatio 为 0，则目标在视野外，
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      let image = new Image()
      let src = entry.target.getAttribute('data-sr')
      if (src) {
        entry.target.setAttribute('data-sr', '')
        image.src = window.location.origin + '/' + src
        image.onload = (e) => {
          entry.target.setAttribute('src', e.target.src)
        }

      } else {
        console.log('isload', entry.target)
      }
      
    }
    else {
      // console.log(entry, "remove active");
    }
  });
});
export default {
  bind(el, binding) {
    el && intersectionObserver.observe(el)
  },
  unbind(el) {
    const key = el.getAttribute('data-key')
    if (key) {
      delete cacheObserverObject[key]
    }
    intersectionObserver.unobserve(el)
  }
};
