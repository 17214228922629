import { defaultOfferWall } from "@/config"
import { request } from "@/plugins/axios.js"
import store from '@/store'

/** 用户登录列表 */
export function userLoginApi(data) {
  // eslint-disable-next-line no-undef
  const channel = offerWall.channel||defaultOfferWall.channel;
  // eslint-disable-next-line no-undef
  const appKey = offerWall.appKey||defaultOfferWall.appKey;
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      // eslint-disable-next-line no-undef
      'Channel-Code': channel +'___' + appKey
    }
  })
}
/** 数据打点 */
export function buriedApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'Channel': 'applovin_int___K4LQJUZ9Q8M79WMN1OBACYU7'
    }
  })
}
/** offer安装列表 */
export function menusListApi(data) {
  return request({
    url: "//v1/wall",
    method: "post",
    data
  })
}
/** 游戏配置列表 */
export function gameConfigApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': store.getters.GET_USER?.at
    }
  })
}
/** offer安装列表 */
export function offerInsListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data
  })
}
/** offer子任务列表 */
export function offerChildListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': store.getters.GET_USER?.at
    }
  })
}
/** offer列表 */
export function offerListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': store.getters.GET_USER?.at
    }
  })
}

/** 提现列表 */
export function cashListApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data
  })
}
/** 提现申请 */
export function cashApplyApi(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': store.getters.GET_USER?.at 
    }
  })
}
/** h5请求同步进度数据 */
export function syncProgress(data) {
  return request({
    url: "/v1/wall",
    method: "post",
    data,
    headers:{
      'accesstoken': store.getters.GET_USER?.at
    }
  })
}
