import Vue from "vue";
import Vuex from "vuex";
import * as api from "@/api/common/index.js";
import {defaultOfferWall} from '@/config/index';
import { getLocal,setLocal,removeLocal } from "@/utils";
import { set } from "lodash";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    search:'',//点击分类展示数据
    recentlyGame:getLocal('recentlyGame') || [],//最近玩的游戏
  },
  getters: {
    GET_SEARCH (state) {
      return state.search
    },
    GET_RECENTLYGAME(state){
      return state.recentlyGame
    }
  },
  mutations: {
    change_search (state, data) {
      state.search = data;
    },
    change_recentlyGame (state, data) {
      removeLocal('recentlyGame');
      if (state.recentlyGame.includes(data)) {  // 判断6是否在数组中
        state.recentlyGame = [data, ...state.recentlyGame.filter(num => num !== data)];  // 使用filter方法剔除6并在前面添加6
      }else{
        state.recentlyGame.unshift(data);
      }
      setLocal('recentlyGame',state.recentlyGame);
      console.log(state.recentlyGame,'state.recentlyGame');
    },
  },
  actions: {
    action_search ({ commit }, data) {
      console.log(data,'data');
      commit("change_search", data);
    },
    action_recentlyGame ({ commit }, data) {
      console.log(data,'data');
      commit("change_recentlyGame", data);
    },
  }
});

export default store;
