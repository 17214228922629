
export default (conf, callback) => {
  console.log('mock', conf.url)

  const dataMock = new Map()
  // dataMock.set('/menu/list', [
  //     {
  //       id: 1,
  //       code: 5000,
  //       down: false,
  //       title: 'MY App',
  //       desc: 'Play for <span>105</span>  minutes recieve all',
  //       url: '',
  //       logo: '',
  //       process: '100',
        
  //     },
  //     {
  //       id: 1,
  //       code: 5000,
  //       down: false,
  //       title: 'MY App',
        
  //       desc: 'Play for <span>100</span>  minutes recieve all',
  //       url: '',
  //       logo: '',
  //       process: '100',
  //       list: [
  //         {title: 'Open and get reward1', money: '2000', id: 11, active: true},
  //         {title: 'Open and get reward2', money: '2000', id: 11},
  //         {title: 'Open and get reward3', money: '2000', id: 11},
  //       ],
        
  //     },
  //     {
  //       id: 1,
  //       code: 5000,
  //       down: false,
  //       title: 'MY App',
  //       url: '',
  //       logo: '',
  //       process: '100',
  //       all: 4,
  //       step: 2,

  //     },
  //     {
  //       id: 1,
  //       code: 5000,
  //       down: false,
  //       title: 'MY App',
  //       all: 4,
  //       step: 1,
  //       url: '',
  //       logo: '',
  //       process: '100',
  //       list: [
  //         {title: 'Open and get reward1', money: '2000', id: 11, active: true},
  //         {title: 'Open and get reward2', money: '2000', id: 11},
  //         {title: 'Open and get reward3', money: '2000', id: 11},
  //       ],
  //     }

  //   ]
  // )

  if (dataMock.get(conf.url)) {
      return new Promise((resolve)=> {
        setTimeout(() => {
          resolve({
              code: 0,
              data: dataMock.get(conf.url),
              msg: 'success'
          })
        }, 1000)
      })
  } else {
    return callback(conf)
  }
}
