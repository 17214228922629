"use strict";
import axios from "axios";
import mockQr from './mock'
//** 创建请求实例 */
function createService() {
  // 创建一个 Axios 实例
  const service = axios.create()
  // 请求拦截
  service.interceptors.request.use(
    (config) => {
      return config
    },
    // 发送失败
    (error) => Promise.reject(error)
  )
  // 响应拦截（可根据具体业务作出相应的调整）
  service.interceptors.response.use(
    (response) => {
      console.log(response, 'response')
      // apiData 是 API 返回的数据
      const apiData = response.data 
      const code = apiData.code
      switch (code) {
        case 0:
          return apiData
        case 100010:
          return apiData
        case 100020:
          return apiData
        case 100030:
          return apiData
        case 100040:
          return apiData
        default:
          // 不是正确的 Code
          return Promise.resolve({
            code: apiData.code,
            data: undefined,
            message: apiData.message
          })
      }
    },
    (error) => {
      // Status 是 HTTP 状态码
      if (!error.response?.status) {
        return 
      }
      const status = error?.response.status 
      switch (status) {
        case 401:
          break
        default:
          break
      }
      return Promise.resolve({
        code: error.response.status,
        data: undefined,
        message: error.message
      })
    }
  )
  return service
}

/** 创建请求方法 */
function createRequestFunction(service) {
  return function(config) {
    const configDefault = {
      headers: {
        // 携带 Token
        Authorization: "Basic SzRMUUpVWjlROE03OVdNTjFPQkFDWVU3OkpCMEFDNkI3OUUyMFRTT1JFN1BEOUw1S05BVTNPT1BK",
        'Content-Type': 'application/json'
      },
      // timeout: 5000,
      baseURL: process.env.VUE_APP_BASE_API,
      data: {}
    }
    if (config.headers?.type == "form") {
      delete config.headers.type
      configDefault.headers["Content-Type"] = "application/x-www-form-urlencoded"
    }
    return mockQr(config, async (config) => {
      return service(
        Object.assign(configDefault, config, {headers: {...configDefault.headers, ...config.headers}}))
    })
  }
}

/** 用于网络请求的实例 */
export const service = createService()

export const request = createRequestFunction(service)

