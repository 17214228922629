import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store';
import directives from './directives';
import './styles/app.scss';
Vue.config.productionTip = false
Object.keys(directives).forEach(directive => {
  Vue.directive(directive, directives[directive])
})
new Vue({
  store,
  router,
  directives,
  render: h => h(App)
}).$mount('#app')
