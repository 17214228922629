import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/layout.vue'),
    meta: { flag: true },
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index.vue'),
        meta: { 
          flag: true ,
          showHeader:true
        },
      },
      {
        path: '/detail',
        name: 'index-detail',
        component: () => import('@/views/detail.vue'),
        meta: { 
          flag: true ,
          showHeader:false
        },
      }
    ]
  },
  {
    path: '/play',
    name: 'index-play',
    component: () => import('@/views/play.vue'),
    meta: { flag: true }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { flag: true }
  },
]

const router = new VueRouter({
  routes
})
const whiteList = ['/404']

export default router
